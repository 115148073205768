<template>
  <div class="orderlist bj order-container1">
    <div class="title">
      <el-button
        type="primary"
        icon="el-icon-refresh"
        class="bjsearch"
        @click="Refresh"
      ></el-button>
      <div class="seach">
        <div class="inputs">
          <el-input
            v-model="from1.order_no"
            placeholder="请输入订单编号"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-date-picker
            @change="payChange"
            v-model="pay_at"
            :time-arrow-control="true"
            type="datetimerange"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
            range-separator="—"
            start-placeholder="支付时间起"
            end-placeholder="支付时间止"
          >
          </el-date-picker>
        </div>
        <div class="inputs multiple-select">
          <el-select
            @change="cityChange"
            filterable
            multiple
            v-model="from1.logistics_id"
            clearable
            placeholder="选择城市仓"
          >
            <el-option
              v-for="item in storelist"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="inputs multiple-select">
          <el-select
            v-model="from1.delivery_id"
            multiple
            clearable
            placeholder="选择自提点"
          >
            <el-option
              v-for="item in selflist"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select v-model="from1.state" clearable placeholder="请选择状态">
            <el-option label="全部订单状态" value=""></el-option>
            <el-option label="待付款" value="1"></el-option>
            <el-option label="已付款" value="2"></el-option>
            <el-option label="已取消" value="3"></el-option>
            <el-option label="已生成供货单" value="4"></el-option>
            <el-option label="已完成" value="5"></el-option>
          </el-select>
        </div>
        <el-button @click="search" type="primary" icon="el-icon-search"
          >查询</el-button
        >
        <el-button @click="exportHandle" type="warning" icon="el-icon-upload2"
          >导出</el-button
        >
      </div>
    </div>

    <div class="auto-table-flex">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
        ref="multipleTable"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column type="index" width="100px" align="center" label="序号">
        </el-table-column>
        <el-table-column prop="order_no" align="center" label="订单编号">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.order_no) }}
          </template>
        </el-table-column>
        <el-table-column prop="create_time" align="center" label="订单创建时间">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.create_time) }}
          </template>
        </el-table-column>
        <el-table-column prop="pay_at" align="center" label="客户名称">
          <template slot-scope="scope" v-if="scope.row.member">
            {{ $empty.empty(scope.row.member.fullname) }}
          </template>
        </el-table-column>
        <el-table-column prop="pay_at" align="center" label="支付时间">
          <template slot-scope="scope">
            {{ $empty.time(scope.row.pay_at) }}
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="订单状态">
          <template slot-scope="scope">
            <span v-if="scope.row.state == 1">待付款</span>
            <span v-else-if="scope.row.state == 2">已付款</span>
            <span v-else-if="scope.row.state == 3">已取消</span>
            <span v-else-if="scope.row.state == 4">已生成供货单</span>
            <span v-else-if="scope.row.state == 5">已完成</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="订单金额">
          <template slot-scope="scope">
            {{ $empty.empty(tool.format_money(scope.row.order_amt)) }}
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="城市仓">
          <template
            slot-scope="scope"
            v-if="
              scope.row.store_delivery && scope.row.store_delivery.logistics
            "
          >
            {{ $empty.empty(scope.row.store_delivery.logistics.name) }}
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="自提点">
          <template slot-scope="scope" v-if="scope.row.store_delivery">
            {{ $empty.empty(scope.row.store_delivery.name) }}
          </template>
        </el-table-column>

        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <router-link
              :to="{ name: 'logOrderDetail', query: { id: scope.row.id } }"
              class="main-color pointer text-none"
              >查看详情
            </router-link>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="from1.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { Loading } from "element-ui";
import { BASE } from "@/api";
export default {
  name: "orderlist",
  components: {},
  data() {
    return {
      //列表新建页面初始参数
      loading: true,
      pay_at: "",
      from: {
        order_no: "",
        pay_start: "",
        pay_end: "",
        logistics_id: "",
        delivery_id: "",
        state: "",
        page: 1,
        pageSize: 10,
      },
      from1: {
        order_no: "",
        pay_start: "",
        pay_end: "",
        logistics_id: "",
        delivery_id: "",
        state: "",
        page: 1,
        pageSize: 10,
      },
      tableData: [],
      currentPage: 1,
      total: 0,
      //列表页面不同功能参数
      authentication: [
        {
          id: -1,
          name: "全部",
        },
        {
          id: 0,
          name: "未认证",
        },
        {
          id: 1,
          name: "审核中",
        },
        {
          id: 2,
          name: "审核成功",
        },
        {
          id: 3,
          name: "审核失败",
        },
      ],
      options: [
        {
          value: "logistics",
          label: "集配",
        },
        {
          value: "delivery",
          label: "自提",
        },
      ],
      options1: [],
      storelist: [],
      selflist: [],
      logistics_id: "",
      list: [],
      list1: [],
    };
  },
  created() {
    if (window.location.href.indexOf("?") > -1) {
      /** 历史丢参问题，修复 start */
      const url = this.tool.getUrl(window.location.href);
      // 无效的值
      const invalid = ["", null, undefined, "null", "undefined"];
      url.page = (invalid.includes(url.page) && 1) || url.page;
      url.pageSize = (invalid.includes(url.pageSize) && 10) || url.pageSize;
      /** 历史丢参问题，修复 end */
      this.from = url;
      this.from1 = url;
      this.pay_at = [this.from1.pay_start, this.from1.pay_end];
      this.currentPage = Number(this.from1.page);
      this.from1.pageSize = Number(this.from1.pageSize);
      console.log(this.from1);
      if (this.from1.logistics_id && this.from1.logistics_id.length > 0) {
        this.getListData(this.from1.logistics_id);
      }
    }
    this.hqlist();
    this.citylist();
    //   this.seletlist();
  },
  methods: {
    getListData(val) {
      this.$api.logistics
        .entruckDeliverylist({
          logistics_id: val, //必须填写
          page: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log(res, "获取数据");
          this.selflist = res.data.data;
        });
    },
    exportHandle() {
      let delivery_id = "",
        logistics_id = "";
      if (
        (Array.isArray(this.from1.delivery_id) &&
          this.from1.delivery_id.length == 0) ||
        !this.from1.delivery_id
      ) {
        delete this.from1.delivery_id;
      } else {
        this.from1.delivery_id.forEach((item) => {
          delivery_id += "&delivery_id[]=" + item;
        });
        console.log(delivery_id);
      }
      if (
        (Array.isArray(this.from1.logistics_id) &&
          this.from1.logistics_id.length == 0) ||
        !this.from1.logistics_id
      ) {
        delete this.from1.logistics_id;
      } else {
        this.from1.logistics_id.forEach((item) => {
          logistics_id += "&logistics_id[]=" + item;
        });
        console.log(logistics_id);
      }
      window.open(
        BASE.PRO2 +
          `/order/list/export?order_no=` +
          this.from1.order_no +
          "&pay_end=" +
          this.from1.pay_end +
          "&pay_start=" +
          this.from1.pay_start +
          "&state=" +
          this.from1.state +
          delivery_id +
          logistics_id +
          "&token=" +
          sessionStorage.getItem("token"),
        "_blank"
      );
      console.log(
        BASE.PRO2 +
          `/order/list/export?order_no=` +
          this.from1.order_no +
          "&pay_end=" +
          this.from1.pay_end +
          "&pay_start=" +
          this.from1.pay_start +
          "&state=" +
          this.from1.state +
          delivery_id +
          logistics_id +
          "&token=" +
          sessionStorage.getItem("token"),
        "_blank"
      );
      // this.$api.general.orderExport(this.from).then((res) => {
      // });
    },
    citylist() {
      this.$api.logistics
        .entruckLogisticslist({
          name: "",
          page: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log(res, "获取数据");
          this.storelist = res.data.data;
        });
    },
    cityChange(val) {
      this.getListData(val);
    },
    handleSelectionChange(val) {
      // this.multipleSelection = val;
      console.log(val);
    },
    toggleSelection() {
      this.$nextTick(() => {
        this.$refs.multipleTable.toggleAllSelection();
      });
    },
    listHandle() {
      this.$confirm("确定要生成供货单吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          let load = Loading.service({ fullscreen: false });
          console.log(load);
          this.tool.debounce(() => {
            this.from = this.tool.DeepCopy({}, this.from1);
            if (
              (Array.isArray(this.from.delivery_id) &&
                this.from.delivery_id.length == 0) ||
              !this.from.delivery_id
            ) {
              delete this.from.delivery_id;
            }
            if (
              (Array.isArray(this.from.logistics_id) &&
                this.from.logistics_id.length == 0) ||
              !this.from.logistics_id
            ) {
              delete this.from.logistics_id;
            }
            this.$api.general
              .addOrder(this.from)
              .then((res) => {
                this.tool.message("已生成", "success");
                load.close();
                this.Refresh();
              })
              .catch((error) => {
                load.close();
              });
          });
        })
        .catch(() => {});
    },
    search() {
      this.from.page = 1;
      this.from1.page = 1;
      this.currentPage = 1;
      this.from = this.tool.DeepCopy({}, this.from1);
      if (
        (Array.isArray(this.from.delivery_id) &&
          this.from.delivery_id.length == 0) ||
        !this.from.delivery_id
      ) {
        delete this.from.delivery_id;
      }
      if (
        (Array.isArray(this.from.logistics_id) &&
          this.from.logistics_id.length == 0) ||
        !this.from.logistics_id
      ) {
        delete this.from.logistics_id;
      }
      this.hqlist();
      this.from.delivery_id = this.from.delivery_id
        ? this.from.delivery_id
        : "";
      this.from.logistics_id = this.from.logistics_id
        ? this.from.logistics_id
        : "";
      this.tool.getResult(this.from, window.location.href);
    },
    changestore(e) {
      // console.log(e);
      this.ruleForm.name = JSON.parse(e).name;
      this.ruleForm.store_id = JSON.parse(e).id;
    },
    addclick() {
      console.log("新增");

      this.ruleForm = {
        business_type_code: "logistics", //集配：logistics 自提点：delivery
        store_id: "",
        name: "",
        itemdata: "",
        username: "",
        password: "",
      };

      this.centerDialogVisible = true;
      this.title = "新增中心账号";
    },
    tianku() {
      console.log(this.ruleForm);
      this.centerDialogVisible = false;
      this.$api.login.addbusiness(this.ruleForm).then((res) => {
        this.$message({
          type: "success",
          message: "添加成功",
        });
        this.Refresh();
      });
    },
    Refresh() {
      this.pay_at = "";
      this.from = {
        order_no: "",
        pay_start: "",
        pay_end: "",
        logistics_id: "",
        delivery_id: "",
        state: "",
        page: 1,
        pageSize: 10,
      };
      this.from1 = {
        order_no: "",
        pay_start: "",
        pay_end: "",
        logistics_id: "",
        delivery_id: "",
        state: "",
        page: 1,
        pageSize: 10,
      };
      this.currentPage = 1;
      this.tool.getResult(this.from1, window.location.href);
      this.hqlist();
    },
    handleSizeChange(val) {
      //   console.log(`每页 ${val} 条`);
      this.from.pageSize = val;
      this.from1.pageSize = val;
      this.tool.getResult(this.from1, window.location.href);
      this.hqlist();
    },
    handleCurrentChange(val) {
      this.from.page = val;
      this.from1.page = val;
      this.currentPage = val;
      this.tool.getResult(this.from1, window.location.href);
      this.hqlist();
    },
    payChange(val) {
      console.log(val);
      if (val) {
        this.from1.pay_start = val[0];
        this.from1.pay_end = val[1];
      } else {
        this.from1.pay_start = "";
        this.from1.pay_end = "";
      }
    },
    handleEdit(index, row) {
      this.$confirm(
        row.status === 1
          ? "确定要禁用该中心账号吗？"
          : "确定要启用该中心账户吗？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        }
      )
        .then(() => {
          this.$api.login
            .businessstatus({
              id: row.id,
              status: row.status === 1 ? 0 : 1,
            })
            .then((res) => {
              console.log(res, "禁用或者启用");
              this.$message({
                type: "success",
                message: row.status === 1 ? "禁用成功" : "启用成功",
              });
              this.hqlist();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: row.status === 1 ? "取消禁用" : "取消启用",
          });
        });
    },

    selectda(index, row) {
      this.title = "修改中心账号";
      this.ruleForm.business_type_code = row.business_type_code;
      //修改参数显示不全，问题
      // if (row.business_type_code == "logistics") {
      //   this.list.forEach((el) => {
      //     if (el.id == row.store_id) {
      //       this.ruleForm.itemdata = JSON.stringify(el);
      //     }
      //   });
      // } else {
      //   this.list1.forEach((el) => {
      //     if (el.id == row.store_id) {
      //       this.ruleForm.itemdata = JSON.stringify(el);
      //     }
      //   });
      // }
      console.log(this.ruleForm, this.list, this.list);
      this.centerDialogVisible = true;
      // this.shownode = false;
      // this.$api.shipper.shipperinfo({ id: row.id }).then((res) => {
      //   console.log(res, "获取数据");
      //   this.info = res.data;
      //
      // });
    },
    hqlist() {
      this.loading = true;
      this.$api.logistics.entruckOrderlist(this.from).then((res) => {
        console.log(res, "获取数据");
        this.tableData = res.data.data;
        this.total = res.data.total;
        this.loading = false;
      });
    },
    seletlist() {
      this.$api.logistics.unusedall().then((res) => {
        console.log(res, "获取所有可配置的自提点");
        this.list1 = res.data;
      });
      this.$api.logistics.logisticsunusedall().then((res) => {
        console.log(res, "获取所有可配置的城市仓");

        this.list = res.data;
      });
    },
  },
};
</script>
<style lang="scss">
.order-container1 {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  //   background: #ffffff;
  padding: 10px;

  .checked-box {
    padding: 13px 15px;
  }

  .order-wrapper {
    display: flex;
    align-items: flex-start;
    background: #ffffff;
    padding-top: 10px;
    padding-left: 15px;

    .inputs {
      margin-right: 10px;
    }

    .multiple-select {
      width: 250px;
    }
  }

  .title {
    background: #ffffff;
    padding: 10px 15px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;

    .seach {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;

      .inputs {
        // width: 207px;
        margin-right: 8px;
      }

      .multiple-select {
        width: 250px;
      }
    }

    .bjsearch {
      background: #333333;
      border-color: #333333;
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }

  .fy {
    // background-color: #000;
    box-sizing: border-box;
    padding: 10px 20px;
  }

  .contents {
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 23px;

    box-sizing: border-box;

    .titlname {
      font-size: 17px;
      color: #21c2ac;
      margin-bottom: 25px;
    }

    .imgs {
      display: flex;

      .imglist {
        width: 165px;
        height: 123px;
        border: 1px dashed #a4adc5;
        padding: 14px 8px;
        text-align: center;
        border-radius: 7px;
        box-sizing: border-box;
        margin-right: 16px;

        .div {
          margin-top: 20px;
          padding-bottom: 20px;
        }

        // img{
        //     width: 100%;
        //     height: 100%;
        // }
      }
    }

    .disply {
      display: flex;
      margin-bottom: 22px;
    }

    .mr {
      margin-right: 31px;
    }

    .iteminput {
      display: flex;
      align-items: center;

      .wen {
        width: 77px;
        margin-right: 24px;
        color: #000;
        font-size: 14px;
        text-align: right;
      }

      .el-input {
        width: 302px;
      }
    }
  }

  .colors {
    color: #fa884c;
  }

  .times {
    margin-right: 8px;
  }

  // .el-picker-panel {
  //   left: 1245px !important;
  // }
}
</style>
